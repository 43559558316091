.open {
    display: flex;
    flex-direction: column;
    position: relative;

    .icon {
        display: none !important;
    }

    .acc-descricao {
        display: unset !important;
        box-shadow: 0px 5px 0px #0000004D;
        border-radius: unset !important;
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .accordion {
        box-shadow: 0px 5px 20px #0000004D;
        border-radius: unset !important;
        border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;

        .acc-t-container {
            span {
                color: #3ACFF7;
            }

        }
    }
}


.ce-icon-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 65%;
    transition: .3s;
}

.card-expand-top {
    border-radius: 4px;
    min-height: 9.375rem;
    min-width: 20.5rem;
    max-width: 20.5rem;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: #121125 0% 0% no-repeat padding-box;

    span {
        font: $normal normal 600 40px/44px $rajdhani;
        font-family: $rajdhani;
        letter-spacing: $no-spacing;
        color: $white;
        text-align: center;
        font-size: 2.5rem;
    }

    &:hover {
        .ce-icon-container {
            top: 70%;
        }
    }

}
