$breakpoint: 1140px;

@media screen and (max-width: $breakpoint) {

  .prev-arrow,
  .next-arrow {
    display: none !important;
  }
}

.swiper-pagination {
  position: unset !important;
  height: 53px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 32px;

  .swiper-pagination-bullet {
    background-color: $darkBlue;
    opacity: 1;
    margin: 0;

    &.swiper-pagination-bullet-active {
      background-color: $blue;
      box-shadow: 0 0 10px $blue;
    }
  }
}

.ab-c-carousel {
  position: relative;
}


.prev-arrow {
  position: absolute;
  top: 40%;
  left: -50px;
  z-index: 10;
  cursor: pointer;
}

.next-arrow {
  position: absolute;
  top: 40%;
  right: -50px;
  z-index: 10;
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.card-mentor {
  width: 100%;
  height: 350px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: all .3s ease-in-out;

  .shadow-layer {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    height: 80%;
    opacity: 0;
    background: linear-gradient(to top, black, transparent);
    transition: all .3s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;

    filter: grayscale(1);
  }

  .cm-flex {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    align-items: flex-end;
    z-index: 1;
    transition: all .3s ease-in-out;
    opacity: 0;

    .title {
      font: $normal normal 600 calc($base-font-size / $base-line-height) $rajdhani;
      font-family: $rajdhani;
      letter-spacing: $no-spacing;
      color: $white;
      font-size: 1.25rem;
    }

    .desc {
      margin-top: 0.5rem;
      font-family: $roboto;
      font-size: 16px;
      line-height: 20px;
      font-weight: $light;
      letter-spacing: $no-spacing;
      color: $white;
    }

    .linkedin {
      margin-left: 0.2rem;
      cursor: pointer;

      &:hover {
        svg>path {
          fill: #3acff7;
        }
      }
    }
  }

  &:hover {
    border: 2px solid #3ACFF7;
    box-shadow: 0 0 5px $blue;
    transition: all .3s ease-in-out;

    .cm-flex {
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    .shadow-layer {
      opacity: 1;
      transition: all .3s ease-in-out;
    }
  }

  @media screen and (max-width: $screen_sm) {
    height: 240px;
    border: 2px solid #3ACFF7;
    box-shadow: 0 0 5px $blue;

    .cm-flex {
      display: flex;
    }

    .shadow-layer {
      display: unset !important;
    }
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  left: -10px;
}