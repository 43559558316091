.sucesso {
    background: url("../../img/bg/Grupo\ 153.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: calc(100vh - 94.5px);
    max-height: calc(100vh - 94.5px);

    display: flex;

    span {
        width: 50%;
        font: $normal normal 600 40px/78px $rajdhani;
        font-family: $rajdhani;
        font-size: 80px;
        padding: 10vw 6vw;
        color: $white;
    }
}

@media screen and (max-width: 1200px) {
    .sucesso {
        span {
            width: 100% !important;
            font-size: 60px !important;
            text-align: center;
            
        }
    }
}

@media screen and (max-width: 600px) {
    .sucesso {
        span {
            font-size: 50px !important;
            text-align: left;
            line-height: 58px;
            padding: 20vw 6vw;
        }
    }
}