// screen sizes
$screen_xs: 576px;
$screen_sm: 768px;
$screen_md: 992px;
$screen_lg: 1200px;
$screen_xl: 1720px;

// container widths
$container_xs: 375px;
$container_sm: 540px;
$container_md: 735px;
$container_lg: 960px;
$container_xl: 1160px;
$container_max: 1640px;

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: $container_max;
  max-width: 100%;

  @media screen and (max-width: $screen_xl) {
    width: $container_xl;
  }

  @media screen and (max-width: $screen_lg) {
    width: $container_lg;
  }

  @media screen and (max-width: $screen_md) {
    width: $container_md;
  }

  @media screen and (max-width: $screen_sm) {
    width: $container_sm;
  }

  @media screen and (max-width: $screen_xs) {
    width: $container_xs;
  }

  @media screen and (max-width: 445px) {
    width: 85%;
  }
}

.container-screen {
  @extend .container;
  width: 1440px;

  @media screen and (max-width: $screen_xl) {
    width: $container_xl;
  }

  @media screen and (max-width: $screen_lg) {
    width: $container_lg;
  }

  @media screen and (max-width: $screen_md) {
    width: $container_md;
  }

  @media screen and (max-width: $screen_sm) {
    width: $container_sm;
  }

  @media screen and (max-width: $screen_xs) {
    width: $container_xs;
  }

  @media screen and (max-width: 445px) {
    width: 85%;
  }
}