@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600&family=Roboto:wght@100;200;300;400;500&display=swap');

$orange: #FA510A;
$blue: #3ACFF7;
$darkBlue: #262FC5;
$pink: #F451FF;
$white: #FFFFFF;
$black: #000;

$roboto: 'Roboto', sans-serif;
$rajdhani: 'Rajdhani', sans-serif;
$normal: normal;
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$base-font-size: 20px;
$no-spacing: 0px;
$letter-spacing-4-8: 4.8px;
$base-line-height: 26px;
$uppercase: uppercase;

$header-height: 88px;

/* Character Styles */
.style-1 {
    font-family: $roboto;
    font-style: $normal;
    font-weight: $regular;
    font-size: $base-font-size;
    line-height: $base-line-height;
    letter-spacing: $no-spacing;
    color: $white;
}

.style-2 {
    font-family: $rajdhani;
    font-style: $normal;
    font-weight: $medium;
    font-size: $base-font-size;
    line-height: $base-line-height;
    letter-spacing: $letter-spacing-4-8;
    color: $blue;
    text-transform: $uppercase;
}

.style-3 {
    font-family: $rajdhani;
    font-style: $normal;
    font-weight: $medium;
    font-size: $base-font-size;
    line-height: $base-line-height;
    letter-spacing: $letter-spacing-4-8;
    color: $orange;
    text-transform: $uppercase;
}