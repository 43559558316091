$content-width: 1000px;
$breakpoint: 1140px;
$nav-background: #121125;
$nav-font-color: #0c0c0c;
$link-hover-color: #f8b500;

header#nav-bar {
  background: $nav-background;
  position: sticky;
  top: 0;
  z-index: 3;
  transition: .1s;
  position: sticky;
  height: $header-height;
  transition: opacity .3s linear;

  &.bg-tr {
    opacity: 0.899;
    transition: opacity .3s linear;
    
    &:hover {
      opacity: 1;
      transition: opacity .3s linear;
    }
  }

  .bg-layer-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    filter: blur(6px);
  }

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    gap: 80px;

    .smart-logo {
      position: relative;
      z-index: 1;
      margin-bottom: -4px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../img/logo.png");
        background-repeat: no-repeat;
        transition: all .3s ease-in-out;
      }

      &:hover {
        &::before {
          filter: blur(6px);
          transition: all .3s ease-in-out;
        }
      }
    }

    nav {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      height: $header-height;

      ul {
        list-style: none;

        &.nav-list {
          display: flex;
          flex-direction: row;
          gap: 40px;
          align-self: flex-end;
        }
        
        &.to-be-smart { align-self: center; }

        li {
          position: relative;
          text-align: center;
          height: 70px;

          a {
            font: $regular normal normal 16px/21px $rajdhani;
            font-family: $rajdhani;
            color: $blue;
            text-align: center;
            font: normal normal normal 16px/21px Rajdhani;
            letter-spacing: 0.64px;
            text-transform: uppercase;
            opacity: 1;
            display: block;
            line-height: 60px;
            height: 100%;
            text-decoration: none;
            transition: all .3s ease-in-out;
            
            &::after {
              content: "";
              position: absolute;
              z-index: 1;
              left: 0;
              right: 0;
              height: 2px;
              background-color: $blue;
              bottom: -1px;
              border-radius: 1px;
              box-shadow: none;
              transition: all .3s ease-in-out;
              opacity: 0;
            }

            &:hover {
              font-weight: var(--semibold);
              text-shadow: 0 0 5px $blue;
              transition: all .3s ease-in-out;

              &::after {
                opacity: 1;
                box-shadow: 0 0 5px 0 $blue;
                transition: all .3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  .mobile {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: $header-height;
    background-color: rgba(18, 17, 37, .8);
    backdrop-filter: blur(15px);
    padding-top: 80px;

    .layer {
      position: absolute;
      opacity: 0.4;
    }

    li {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        font-family: $rajdhani;
        font-size: 20px !important;
        line-height: 26px !important;
        letter-spacing: 0.24em !important;
        height: unset !important;
      }
    }
  }

  .to-be-smart {
    text-align: center;
    min-width: 278px;
  }

  /* mobile menu toggle button */
  .menu-toggle {
    position: relative;
    display: none;
    width: 40px;
    height: 30px;
  }

  .menu-toggle span {
    margin: 0 auto;
    position: relative;
    top: 12px;
    transition-duration: 0s;
    transition-delay: .2s;
    transition: background-color 0.3s;
  }

  .menu-toggle span:before,
  .menu-toggle span:after {
    position: absolute;
    content: '';
  }

  .menu-toggle span,
  .menu-toggle span:before,
  .menu-toggle span:after {
    width: 32px;
    height: 2px;
    background: transparent linear-gradient(55deg, $blue 0%, #F451FF 50%, $orange 100%) 0% 0% no-repeat padding-box;
    display: block;
    opacity: 1;
  }

  .menu-toggle span:before {
    margin-top: -12px;
    transition-property: margin, transform;
    transition-duration: .2s;
    transition-delay: .2s, 0;
  }

  .menu-toggle span:after {
    margin-top: 12px;
    transition-property: margin, transform;
    transition-duration: .2s;
    transition-delay: .2s, 0;
  }

  .menu-toggle-active span {
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s background-color;
    transform: rotate(-45deg);
  }

  .menu-toggle-active span:before {
    margin-top: 0;
    // transform: rotate(45deg);
    transition-delay: 0, .2s;
  }

  .menu-toggle-active span:after {
    margin-top: 0;
    transform: rotate(-90deg);
    transition-delay: 0, .2s;
  }

  @media screen and (max-width: $screen_md) {
    .to-be-smart {
      min-width: 200px;
    }
  }
  
  @media screen and (max-width: $screen_md) {
    .header-content {
      nav {
        width: 0;

        ul.nav-list, button {
          display: none;
        }
  
        &.open {
          ul.mobile {
            // width: 100%;
            display: block;
          }
      
          ul.nav-list,
          button {
            display: none ;
          }
      
          .mobile {
            display: unset;
          }
        }
      }

      a.menu-toggle {
        display: inline-block;
      }
    }
  }
}