.fnbc-b-mobile {
  display: none;
}

.passos-mobile {
  display: none !important;
  width: 100%;
}

.fnbc-mobile {
  display: none;
}

.table-mobile {
  display: none;
}

.espaçamento-lp-title {
  margin-top: 80px;
  margin-bottom: 40px;
}

.lp-wrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

br {
  @media screen and (max-width: $screen_sm) {
    display: none;
  }
}

.lp-title {
  font-family: $rajdhani;
  font-weight: $medium;
  letter-spacing: $letter-spacing-4-8;
  color: $blue;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  font-size: 1.25rem;
}

.title-h2 {
  font-family: $rajdhani;
  font-size: 40px;
  line-height: 44px;
  font-weight: $medium;
  letter-spacing: $no-spacing;
  color: $white;
  text-align: center;
  font-size: 2.5rem;

  margin-left: auto;
  margin-right: auto;
  margin-top: unset;
  margin-bottom: 12px;

  width: 1000px;
  max-width: 100%;

  @media screen and (max-width: $screen_sm) {
    font-size: 32px;
    line-height: 36px;
    width: 100%;
    margin-bottom: 16px;
  }
}

.descricao {
  font-family: $roboto;
  font-weight: $light;
  letter-spacing: $no-spacing;
  color: $white;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 75px;

  @media screen and (max-width: $screen_sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

#inicio {
  padding-top: $header-height;
  min-height: 85.5vh;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: url("../../img/main-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-top: -$header-height;
  padding-bottom: 77px;

  .backdrop {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 66.5%;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, #121125);
  }

  h1 {
    position: relative;
    z-index: 1;

    font-family: $rajdhani;
    font-size: 48px;
    line-height: 62px;
    font-weight: $medium;
    letter-spacing: $no-spacing;
    color: $white;
    text-align: center;
    letter-spacing: 0px;
    margin-bottom: 48px;
  }

  .to-be-smart-i {
    text-align: center;
    z-index: 1;

    button {
      max-width: 23.5rem;
      padding-top: 1.063rem;
      padding-bottom: 1.063rem;
    }
  }

  @media screen and (max-width: $screen_sm) {
    background-position: top right 60%;

    h1 {
      font-size: 32px;
      line-height: 36px;
    }

    .container-button-g {
      button.button-g {
        width: 300px;
      }
    }
  }
}

#future-startups {
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: center;

  .subtitle {
    font-family: $rajdhani;
    font-size: 20px;
    font-weight: $regular;
    line-height: 26px;
    letter-spacing: 4.8px;
    color: $pink;
    font-size: 1.25rem;
    display: block;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  ul.startups {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    box-sizing: border-box;
    justify-content: center;

    .startup-card {
      position: relative;
      background: #ffffff;
      border-radius: 6px;
      width: calc(19% - 5px);
      height: 80px;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-sizing: border-box;

      img {
        max-width: 100%;
        max-height: 100%;
        box-sizing: border-box;
      }
    }
  }

  @media screen and (max-width: $screen_md) {
    ul.startups {
      justify-content: center;

      .startup-card {
        flex: calc(25% - 5px) 1;
        max-width: 240px;
      }
    }
  }

  @media screen and (max-width: $screen_sm) {
    ul.startups {
      .startup-card {
        flex: calc(50% - 5px) 1;
      }
    }
  }

  @media screen and (max-width: $screen_xs) {
    ul.startups {
      justify-content: center;

      .startup-card {
        padding: 16px;
      }
    }
  }
}

#conheca {
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #121125, #262fc5);
  padding-bottom: 35px;

  .gp-23 {
    position: absolute;
    left: 0;
    min-height: 150px;
    z-index: 0;
    right: 50%;
    top: 886px;
    transform: translate(-60px, 0);
  }

  .container-screen {
    position: relative;
    z-index: 2;
  }

  .cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 80px;
  }

  .diferente {
    position: relative;
    height: auto;

    span {
      font-family: $rajdhani;
      font-size: 20px;
      font-weight: $semibold;
      line-height: 26px;
      letter-spacing: $no-spacing;
      color: $pink;
      font-size: 1.25rem;
      display: block;
      margin-bottom: 16px;
    }

    .distancia {
      transform: translateY(0);
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .distancia {
        transform: translateY(8px);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  @media screen and (max-width: $screen_lg) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: $screen_md) {
    .gp-23 {
      top: 1086px;
    }
  }

  @media screen and (max-width: $screen_sm) {
    .gp-23 {
      display: none;
    }

    .descricao {
      br {
        display: block;
      }
    }
  }
}

#sobre,
#cardTypes {
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  background: #262fc5 0% 0% no-repeat padding-box;

  .container-screen {
    position: relative;
    z-index: 1;
  }

  .descricao {
    margin-bottom: 45px;
  }

  .mecanismos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 137px;
    gap: 16px;

    .numero-mobile {
      display: none;
    }

    .primeiro,
    .segundo {
      display: flex;
      flex-direction: row;
    }

    .primeiro {
      margin-left: -40px;
    }

    .info {
      max-width: 466px;
      text-align: left;
      padding-top: 30px;

      .title-h3 {
        font: $normal normal $medium 32px/41px $rajdhani;
        font-family: $rajdhani;
        letter-spacing: $no-spacing;
        color: $blue;
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .m-descricao {
        font-family: $roboto;
        font-size: 20px;
        line-height: 26px;
        font-weight: $light;
        letter-spacing: $no-spacing;
        color: $white;
        font-size: 1.25rem;
      }
    }

    h3 {
      margin: 0;
    }
  }

  .bg-escuro {
    background: transparent linear-gradient(180deg, #262fc5 0%, #121125 100%) 0%
      0% no-repeat padding-box;
  }

  .gm-23 {
    position: absolute;
    left: -70%;
    min-height: 957px;
    z-index: 0;
    transform: translate(0px, -82px);

    @media screen and (min-width: $screen_xl) {
      left: -100%;
      right: auto;
    }
  }

  .gp-84 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transform: translate(66%, 104%);
  }

  .table-web {
    position: relative;
    z-index: 1;
  }

  .table {
    width: 1208px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 51px;
    margin-bottom: 23px;

    .table-web,
    .table-mobile {
      padding-top: 0px;
      flex: 1;

      .title-table {
        height: 113px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: $rajdhani;
          font-size: 40px;
          line-height: 44px;
          font-weight: $medium;
          letter-spacing: $no-spacing;
          text-transform: uppercase;
          margin-top: 10px;

          @media screen and (max-width: $screen_md) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }

      .item {
        text-align: left;
        padding: 4px 14px 12px;
        background-color: transparent;
        list-style: none;

        .title {
          font-family: $rajdhani;
          font-size: 16px;
          line-height: 21px;
          font-weight: $semibold;
          letter-spacing: $no-spacing;
          text-transform: uppercase;
          color: $blue;
        }

        .i-descricao {
          font-family: $roboto;
          font-size: 20px;
          line-height: 26px;
          font-weight: $thin;
          letter-spacing: $no-spacing;
          color: $white;
        }

        &:nth-of-type(even) {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &:nth-of-type(1) {
          min-height: 64px;
        }

        &:nth-of-type(2) {
          min-height: 115px;
        }

        &:nth-of-type(3) {
          min-height: 98px;
        }

        &:nth-of-type(4) {
          min-height: 54px;
        }

        &:nth-of-type(5) {
          min-height: 96px;
        }

        &:nth-of-type(6) {
          min-height: 80px;
        }

        &:nth-of-type(7) {
          min-height: 70px;
        }

        &:nth-of-type(8) {
          min-height: 54px;
        }

        &:nth-of-type(9) {
          min-height: 70px;
        }

        &:nth-of-type(10) {
          min-height: 80px;
        }

        @media screen and (max-width: $screen_xl) {
          &:nth-of-type(3) {
            min-height: 115px;
          }
          &:nth-of-type(6) {
            min-height: 90px;
          }
          &:nth-of-type(10) {
            min-height: 110px;
          }
        }

        @media screen and (max-width: $screen_lg) {
          &:nth-of-type(2) {
            min-height: 142px;
          }
          &:nth-of-type(5) {
            min-height: 115px;
          }
          &:nth-of-type(10) {
            min-height: 140px;
          }
        }

        @media screen and (max-width: $screen_md) {
          .title,
          .i-descricao {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      &.incubadora {
        border-radius: 4px 0px 0px 4px;
        border: 2px solid $blue;
        transition: all 0.3s ease-in-out;

        .title-table {
          color: $blue;
          transition: all 0.3s ease-in-out;
        }

        &.table-mobile,
        &:hover {
          box-shadow: 0 0 1em $blue, inset 0 0 1em $blue;
          background-color: rgba(58, 207, 247, 0.2);
          backdrop-filter: blur(15px) opacity(0.2);
          transition: all 0.3s ease-in-out;

          .title-table {
            text-shadow: 0 0 10px $blue;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &.potencializadora-smart {
        padding: 2px 0;
        background: transparent linear-gradient(270deg, $pink 0%, $blue 100%) 0%
          0% no-repeat padding-box;
        transition: all 0.3s ease-in-out;

        .title-table {
          color: $white;
          transition: all 0.3s ease-in-out;
        }

        .item {
          .title {
            color: $darkBlue;
          }

          .i-descricao {
            font-weight: $medium;
          }
        }

        &.table-mobile,
        &:hover {
          box-shadow: 0 0 1em $blue, 0 0 1em $pink;

          .title-table {
            text-shadow: 0 0 10px $white;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &.aceleradora {
        border: 2px solid $pink;
        border-radius: 0px 4px 4px 0px;
        transition: all 0.3s ease-in-out;

        .title-table {
          color: $pink;
        }

        &.table-mobile,
        &:hover {
          box-shadow: 0 0 1em $pink, inset 0 0 1em $pink;
          background-color: rgba(244, 81, 255, 0.2);
          backdrop-filter: blur(15px) opacity(0.2);
          transition: all 0.3s ease-in-out;

          .title-table {
            text-shadow: 0 0 10px $pink;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  div#ser {
    padding-top: 80px;
    padding-bottom: 173px;
    // background: linear-gradient(to bottom, #262FC5, #121125);

    .espaçamento-lp-title {
      margin-top: 0;
      margin-bottom: 47px;
    }

    .descricao {
      font-weight: 100;
    }
  }

  .card-container-find {
    width: 832px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    align-items: center;
    position: relative;
    margin-top: 52px;
    padding: 16px;
    margin-left: auto;
    margin-right: auto;

    .bg-filter {
      position: absolute;
      left: 0;
      border-radius: 4px;
      right: 0;
      height: 100%;
      z-index: 0;
      background-color: rgba(18, 17, 37, 0.5);
      backdrop-filter: blur(15px);
      opacity: 0.5;
    }

    .card--soon {
      box-sizing: border-box;
      display: block;
      border: 2px solid $pink;
      padding: 1.25rem;
      border-radius: 4px;
      .card-content {
        .title {
          color: $pink;
          font-family: $rajdhani;
          font-weight: $semibold;
          font-size: 2rem;
          max-width: 450px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    .card-find {
      --color: white;
      position: relative;
      flex: calc(50% - 8px);
      height: 150px;

      &:nth-of-type(2) {
        z-index: 4;
      }

      &:nth-of-type(3) {
        z-index: 3;
      }

      &:nth-of-type(4) {
        z-index: 2;
      }

      &:nth-of-type(5) {
        z-index: 1;
      }

      .card-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        opacity: 1;
        padding-top: 50px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 150px;
        transition: all 0.3s ease-in-out;
        border: 2px solid $blue;
        overflow: hidden;

        .title {
          width: 100%;
          font-family: $rajdhani;
          font-weight: $semibold;
          font-size: 40px;
          line-height: 44px;
          letter-spacing: $no-spacing;
          color: $white;
          text-align: center;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }

        svg {
          position: absolute;
          top: 67.5%;
          transition: all 0.3s ease-in-out;
          overflow: visible;

          path {
            transition: all 0.3s ease-in-out;
          }
        }

        .card-description {
          display: none;
        }
      }

      &.blue {
        --color: #{$blue};
        .card-content {
          background-color: $blue;
          border-color: $blue;
        }

        &.cards-find-unset,
        &:hover {
          svg path {
            fill: $blue;
            transition: all 0.3s ease-in-out;

            &:last-of-type {
              filter: blur(3px);
            }
          }

          .title {
            color: $blue;
          }
        }

        &.cards-find-unset {
          .card-content {
            background-color: rgba(58, 207, 247, 0.2);
          }
        }
      }

      &.pink {
        --color: #{$pink};
        .card-content {
          background-color: $pink;
          border-color: $pink;
        }

        &.cards-find-unset,
        &:hover {
          svg path {
            fill: $pink;
            transition: all 0.3s ease-in-out;

            &:last-of-type {
              filter: blur(3px);
            }
          }

          .title {
            color: $pink;
          }
        }

        &.cards-find-unset {
          .card-content {
            background-color: rgba(244, 81, 255, 0.2);
          }
        }
      }

      &.orange {
        --color: #{$orange};
        .card-content {
          background-color: $orange;
          border-color: $orange;
          padding-top: 20px;
          svg {
            top: 75%;
          }
        }

        &.cards-find-unset,
        &:hover {
          svg path {
            fill: $orange;
            transition: all 0.3s ease-in-out;

            &:last-of-type {
              filter: blur(3px);
            }
          }

          .title {
            color: $orange;
          }
        }

        &.cards-find-unset {
          .card-content {
            background-color: rgba(250, 81, 10, 0.2);
          }
        }
      }

      &.darkblue {
        --color: #{$darkBlue};
        .card-content {
          background-color: $darkBlue;
          border-color: $darkBlue;
        }

        &.cards-find-unset,
        &:hover {
          svg path {
            fill: $darkBlue;
            transition: all 0.3s ease-in-out;

            &:last-of-type {
              filter: blur(3px);
            }
          }

          .title {
            color: $darkBlue;
          }
        }

        &.cards-find-unset {
          .card-content {
            background-color: rgba(38, 47, 197, 0.2);
          }
        }
      }

      &:hover {
        .card-content {
          background-color: transparent;
          backdrop-filter: blur(10px);
          transition: all 0.3s ease-in-out;

          .title {
            text-shadow: 0 0 10px;
            transition: all 0.3s ease-in-out;
          }

          svg {
            top: 75%;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &.cards-find-unset {
        .card-content {
          padding-top: 30px;
          height: 405px;
          background-color: transparent;
          backdrop-filter: blur(10px);
          transition: all 0.3s ease-in-out;

          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 4px;
            height: 100%;
            box-sizing: border-box;
            margin: 10px 0;
          }
          &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.15);
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(--color);
          }

          &:after {
            content: "";
            clear: both;
          }

          .title {
            text-shadow: 0 0 10px;
            transition: all 0.3s ease-in-out;
          }

          svg {
            display: none;
          }

          .card-description {
            display: block;
            padding-top: 16px;

            span,
            li {
              font-family: $roboto;
              font-size: 20px;
              line-height: 26px;
              font-weight: $thin;
              color: $white;

              strong {
                font-weight: $medium;
              }
            }

            span {
              margin-bottom: 24px;
            }

            li {
              text-align: left;
              margin-top: 16px;
            }
          }
        }
      }
    }

    .display-none {
      display: none;
    }
  }

  #metodologia {
    position: relative;
    padding-top: 22px;
    padding-bottom: 5rem;

    .como-gerar-resultado {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 3.5rem;
      margin-top: 5rem;
      gap: 16px;

      .module-border-wrap {
        position: relative;
        flex: calc(25% - 8px);
        position: relative;
        padding: 2px;
        background-color: rgba(18, 17, 37, 0.8);
        border-radius: 4px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          border-radius: 4px;
          transition: all 0.3s ease-in-out;
        }

        .card-resultado {
          position: relative;
          border-radius: 4px;
          height: 182px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          transition: all 0.3s ease-in-out;

          .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.625rem;

            svg {
              filter: grayscale(1);
              transition: filter 0.3s ease-in-out;
            }

            span {
              margin-left: 1rem;
              color: #707070;
              font: $normal normal normal 32px/40px $rajdhani;
              font-family: $rajdhani;
              letter-spacing: $no-spacing;
              font-size: 2rem;
            }
          }

          .cr-descricao {
            color: #707070;
            font-family: $roboto;
            font-size: 20px;
            line-height: 26px;
            font-weight: $light;
            letter-spacing: $no-spacing;
          }
        }

        &:hover {
          background: linear-gradient(to right, $pink, $orange);
          transition: all 0.3s ease-in-out;

          &:before {
            background: linear-gradient(to right, $pink, $orange);
            transition: all 0.3s ease-in-out;
            filter: blur(4px);
          }

          .card-resultado {
            background-color: rgba(18, 17, 37, 1);
            transition: filter 0.3s ease-in-out;
            box-shadow: inset 0 0 3px $pink inset 0 0 3px $orange;

            .title span {
              color: $orange;
            }

            .title svg {
              filter: grayscale(0);
              transition: filter 0.3s ease-in-out;
            }

            .cr-descricao {
              color: $white;
            }
          }
        }
      }
    }

    .cgr-web {
      display: flex;
    }

    .cgr-mobile {
      display: none;
    }

    @media screen and (max-width: 915px) {
      .cgr-web {
        display: none;
      }

      .cgr-mobile {
        display: flex;

        .module-border-wrap {
          max-width: 344px;
          margin-left: auto;
          margin-right: auto;
          background: linear-gradient(to right, $pink, $orange);
          transition: all 0.3s ease-in-out;

          &:before {
            background: linear-gradient(to right, $pink, $orange);
            transition: all 0.3s ease-in-out;
            filter: blur(4px);
          }

          .card-resultado {
            background-color: rgba(18, 17, 37, 1);
            transition: filter 0.3s ease-in-out;
            box-shadow: inset 0 0 3px $pink inset 0 0 3px $orange;

            .title span {
              color: $orange;
            }

            .title svg {
              filter: grayscale(0);
              transition: filter 0.3s ease-in-out;

              path {
                fill: $orange;
              }
            }

            .cr-descricao {
              color: $white;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  #ser,
  #metodologia {
    position: relative;
    z-index: 2;
  }

  .background-blue-energy {
    position: relative;

    .bright-blue-bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .faca-negocios {
    background: transparent linear-gradient(249deg, $orange 40%, $pink 100%) 0%
      0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 177px;
    min-height: 30rem;
    position: relative;
    z-index: 0;
    padding-right: 72px;

    &:hover {
      box-shadow: 0 0 1em $orange;
    }

    .ondas {
      position: absolute;
      z-index: 1;
      right: -60px;
    }

    .fn-descricao {
      font-family: $roboto;
      font-size: 20px;
      line-height: 26px;
      font-weight: $light;
      color: $white;
    }

    .fn-number {
      font-family: $rajdhani;
      font-size: 7.5rem;
      line-height: 1;
      font-weight: $semibold;
      color: $white;

      @media (min-width: 768px) {
        font-size: 11.25rem;
      }
    }

    .left {
      position: relative;
      z-index: 1;
      max-width: 19.375rem;
      text-align: left;

      .title {
        margin-bottom: 10px;

        span {
          font-family: $rajdhani;
          font-size: 40px;
          line-height: 44px;
          font-weight: $medium;
          letter-spacing: $no-spacing;
          color: $white;
        }
      }

      .fn-b-c {
        margin-top: 34px;

        button {
          padding-top: 1.0063rem;
          padding-bottom: 1.0063rem;
        }
      }
    }

    .right {
      position: relative;
      z-index: 1;

      img {
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: $screen_lg) {
      gap: 80px;
    }

    @media screen and (max-width: $screen_md) {
      flex-direction: column;
      padding: 80px 0;

      img.ondas {
        right: -60px;
        bottom: -60px;
      }

      .right {
        img {
          max-width: 85%;
        }
      }

      .fnbc-web {
        display: none;
      }

      .fnbc-mobile {
        display: block;
      }
    }
  }

  @media screen and (max-width: $screen_lg) {
    .gp-84 {
      transform: translate(66%, 110%);
    }
  }

  @media screen and (max-width: $screen_lg) {
    .gp-84 {
      transform: translate(66%, 120%);
    }
  }

  @media screen and (max-width: $screen_sm) {
    .gp-84 {
      width: 516px;

      -moz-transform: translate(-50%, 160%) scale(-1, 1);
      -o-transform: translate(-50%, 160%) scale(-1, 1);
      -webkit-transform: translate(-50%, 160%) scale(-1, 1);
      transform: translate(-50%, 160%) scale(-1, 1);

      z-index: 0;
    }

    .mecanismos {
      flex-direction: column;

      .primeiro,
      .segundo {
        margin-left: 0;
        width: 300px;
      }

      img.numero-web {
        display: none;
      }

      img.numero-mobile {
        display: block;
        height: 80px;
      }

      .n-mobile-container {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 220px;

        .title-h3 {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .m-descricao {
        font-size: 16px !important;
        line-height: 20px !important;
        padding-left: 20px;
        display: block;
      }
    }

    .table-web {
      display: none;
    }

    .gm-23 {
      display: none;
    }

    .table-mobile {
      display: unset;
      width: 100%;

      .table-mobile {
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;
        display: block !important;

        .title-table {
          span {
            font-size: 32px;
            line-height: 36px;
          }
        }

        .item {
          .title,
          .i-descricao {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    #ser {
      padding-bottom: 0 !important;

      .card-container-find {
        width: 334px;
        max-width: 100%;

        .card-find {
          flex: 100%;
          .card-content {
            padding-left: 16px;
            padding-right: 16px;

            span.title {
              font-size: 32px;
              line-height: 44px;
            }

            .card-description {
              span,
              li {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen_xs) {
    .gp-84 {
      -moz-transform: translate(-50%, 220%) scale(-1, 1);
      -o-transform: translate(-50%, 220%) scale(-1, 1);
      -webkit-transform: translate(-50%, 220%) scale(-1, 1);
      transform: translate(-50%, 220%) scale(-1, 1);
    }
  }
}

#cardTypes {
  background: none;
}

#mentores {
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(360deg, #121125 0%, #262fc5 100%) 0%
    0% no-repeat padding-box;
  border-bottom: #121125 solid 1px;

  .container-car {
    margin-bottom: 5rem;
    height: 326px;
  }
}

#cases {
  position: relative;
  background: transparent
    linear-gradient(180deg, #121125 0%, #121125 33%, #12112500 100%) 0% 0%
    no-repeat padding-box;
  text-align: center;

  .cases-bg-lines {
    position: relative;
    text-align: center;
    padding-bottom: 3.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 740px;
      background-image: linear-gradient(to bottom, #121125 40%, transparent);
      z-index: 1;
    }
  }

  .ct-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  div {
    z-index: 1;
  }

  .video-container {
    display: flex;
    justify-content: center;
    gap: 82px;
    padding-top: 90px;
    padding-bottom: 120px;
  }

  .v-text {
    text-align: left;
    width: 392px;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .v-descricao {
      margin-top: 1rem;
    }
  }

  .v-title {
    font: $normal normal $medium 40px/44px $rajdhani;
    font-family: $rajdhani;
    letter-spacing: $no-spacing;
    color: $white;
    font-size: 2.5rem;
  }

  .v-descricao {
    margin-top: 0.5rem;
    font-family: $roboto;
    font-size: 20px;
    line-height: 26px;
    font-weight: $light;
    letter-spacing: $no-spacing;
    color: $white;
    text-align: left;
  }

  .video {
    .video__iframe {
      border-radius: 4px;
      width: 500px;
      height: 284px;
    }
  }

  .como-se-tornar {
    .espaçamento-lp-title {
      position: relative;
      margin-top: 68px;
      margin-bottom: 47px;
    }

    .title-h2 {
      position: relative;
      margin-bottom: 64px;
    }

    .card-tonar-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;
      align-items: center;

      .icon-arrow {
        position: absolute;
        top: 35%;
        left: -30px;
      }

      .ct-module-border-wrap {
        position: relative;
        padding: 3px;
        border-radius: 4px;
        width: calc(33% - 8px);

        .card-tonar {
          background: rgba(18, 17, 37, 0.8);
          backdrop-filter: blur(15px);
          border-radius: 4px;
          opacity: 1;

          height: 180px;
          max-width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding: 24px 16px;

          border: 2px solid transparent;
          transition: all 0.3s ease-in-out;

          .ct-title {
            font: $normal normal 600 32px/41px $rajdhani;
            font-family: $rajdhani;
            letter-spacing: $no-spacing;
            text-align: center;
            color: $pink;
            text-transform: uppercase;
            font-size: 2rem;
            margin-bottom: 8px;
          }

          .ct-descricao {
            font-family: $roboto;
            font-size: 20px;
            line-height: 26px;
            font-weight: $light;
            letter-spacing: $no-spacing;
            color: $white;
          }
        }

        &:hover {
          .card-tonar {
            border-width: 2px;
            border-style: solid;
            border-image: linear-gradient(to right, $pink, $orange) 1;
            box-shadow: inset 0 0 5px $pink inset 0 0 5px $orange 0 0 5px $pink
              0 0 5px $orange;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen_lg) {
    .passos-web {
      .ct-descricao {
        span {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: $screen_md) {
    .ct-bg {
      object-fit: cover;
    }

    .video-container {
      flex-direction: column;
      align-items: center;
      padding: 80px 0;

      .video {
        iframe {
          width: 392px;
          height: 221px;
        }
      }
    }

    .passos-web {
      display: none !important;
    }

    .passos-mobile {
      display: block !important;

      .ct-module-border-wrap {
        width: 334px !important;
        max-width: 100% !important;

        .icon-arrow {
          display: none !important;
        }

        .card-tonar {
          border-width: 2px !important;
          border-style: solid !important;
          border-image: linear-gradient(to right, $pink, $orange) 1 !important;
          box-shadow: inset 0 0 5px $pink inset 0 0 5px $orange 0 0 5px $pink 0
            0 5px $orange !important;
          transition: all 0.3s ease-in-out !important;

          .ct-descricao {
            font-size: 16px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }
}

#momento {
  position: relative;
  z-index: 2;
  background: $pink;
  display: flex;
  justify-content: center;

  .m-descricao {
    font: $normal normal $regular calc($base-font-size / $base-line-height)
      $roboto;
    font-family: $roboto;
    letter-spacing: $no-spacing;
    color: $white;
    text-align: left;
    font-size: 1.25rem;
  }

  .left-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .left-container {
      box-shadow: 0 0 1em $pink;
      transition: all 0.3s ease-in-out;
    }
  }

  .left {
    max-width: 19.375rem;
    text-align: left;

    .title {
      margin-bottom: 0.5rem;

      span {
        font: $normal normal $medium 40px/44px $rajdhani;
        font-family: $rajdhani;
        letter-spacing: $no-spacing;
        color: $white;
        text-align: left;
        font-size: 2.5rem;
      }
    }

    .fn-b-c {
      margin-top: 2.125rem;

      button {
        padding-top: 1.0063rem;
        padding-bottom: 1.0063rem;
      }
    }
  }

  .m-bg {
    img {
      height: 100%;
      border: none;
      object-fit: cover;
    }
  }

  @media screen and (max-width: $screen_lg) {
    .m-bg {
      max-width: 55%;
    }
  }

  @media screen and (max-width: $screen_md) {
    flex-direction: column;
    padding-top: 80px;

    .left-container {
      margin-bottom: 40px;

      .fnbc-l-web {
        display: none;
      }
    }

    .m-bg {
      position: relative;
      max-width: 100%;

      .fnbc-b-mobile {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        margin-bottom: -5px;
      }
    }
  }
}

#faq {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 175px;
  text-align: center;
  background: transparent linear-gradient(180deg, #000000 0%, #121125 100%) 0%
    0% no-repeat padding-box;

  .espaçamento-lp-title {
    margin-top: 0;
  }

  .title-h2 {
    margin-bottom: 65px;
  }
}

.footer {
  background: #000000 0% 0% no-repeat padding-box;

  .container-footer {
    width: 1200px;
    height: 242px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  button {
    min-width: 262px;
    min-height: 60px;
  }

  @media screen and (max-width: $screen_md) {
    padding: 80px 0;

    .container-footer {
      flex-direction: column;
      gap: 50px;
    }
  }
}
