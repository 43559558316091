.container-button-s {
  position: relative;
}

.button-s {
  cursor: pointer;
  position: relative;
  background: $blue 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 60px;

  border: none;

  font-family: $rajdhani;
  font-size: 20px;
  line-height: 26px;
  font-weight: $semibold;
  letter-spacing: $no-spacing;
  text-transform: uppercase;
  color: #121125;
  width: 280px;
  max-width: 100%;
  transition: 0.3s;
  z-index: 1;

  span {
    z-index: 1;
  }

  // animation
  &:not(:disabled) {
    &:hover {
      color: $white;
      transform: translateY(-6px);

      .layer-bg-s {
        background: $blue 0% 0% no-repeat padding-box;
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        opacity: 0.2;
        z-index: -1;
        border-radius: 10px;
        filter: blur(6px);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: none;
    border: 2px solid currentColor;
    opacity: 0.45;
  }
}
