.container-button-g {
  position: relative;
}

.button-g {
  cursor: pointer;
  background: transparent
    linear-gradient(262deg, $orange 0%, #f451ff 51%, #262fc5 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  border: none;

  font-family: $rajdhani;
  font-weight: $semibold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: $no-spacing;
  color: $white;

  text-transform: uppercase;
  position: relative;
  width: 100%;
  transition: 0.3s;
  z-index: 1;

  span {
    z-index: 1;
  }

  &:focus {
    outline: none;
  }

  .layer-bg-g {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent
      linear-gradient(262deg, $orange 0%, #f451ff 51%, #262fc5 100%) 0% 0%
      no-repeat padding-box;
    // opacity: 0.2;
    z-index: -1;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    filter: blur(0px);
  }

  &:hover {
    transform: translateY(-10px);

    .layer-bg-g {
      filter: blur(8px);
      transition: all 0.3s ease-in-out;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: rgb(95, 95, 95);
    background: none;
    transform: translateY(0);
    
    .layer-bg-g {
      filter: none;
      opacity: 0.45;
      background: none;
      border: 2px solid currentColor;
    }

    &:hover {
      filter: grayscale(0.4);
    }
  }
}
