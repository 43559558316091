.open {
  display: flex;
  flex-direction: column;
  position: relative;

  .acc-descricao {
    display: unset !important;
    box-shadow: 0px 5px 0px #0000004D;
    border-radius: unset !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .accordion {
    box-shadow: 0px 5px 20px #0000004D;
    border-radius: unset !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;

    .acc-t-container {
      span {
        font-weight: $semibold;
        color: #3ACFF7;
      }

      .plus-w,
      .plus-b {
        display: none !important;
      }

      .times {
        display: unset !important;
      }
    }
  }
}

.accordion {
  // width: 100%;
  cursor: pointer;
  min-height: 4.625rem;
  background: #121125 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #0000004D;
  border-radius: 4px;
  position: relative;
  text-align: left;

  padding-left: 1.5rem;
  padding-right: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    .acc-t-container {
      span {
        color: #3ACFF7;
      }

      .plus-w {
        display: none;
      }

      .plus-b {
        display: unset;
      }
    }
  }

  .acc-t-container {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    span {
      font: $normal normal 600 calc($base-font-size / $base-line-height) $rajdhani;
      font-family: $rajdhani;
      letter-spacing: $no-spacing;
      font-weight: $semibold;
      color: $white;
      font-size: 1.25rem;
    }

    .plus-w {
      display: unset
    }

    .plus-b {
      display: none
    }

    .times {
      display: none;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }

  @media screen and (max-width: $screen_md) {
    .acc-t-container {
      span {
        font-size: 16px;
        line-height: 20px;
        font-weight: $semibold;
      }
    }
  }
}

.acc-descricao {
  position: absolute;
  top: 100%;
  display: none;

  width: 100%;
  z-index: 2;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  transition: .3s;
  text-align: left;
  background: #121125 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #0000004D;
  border-radius: 4px;

  span {
    color: #fff;
    font-family: $roboto;
    font-size: 20px;
    line-height: 26px;
    font-weight: $light;
    letter-spacing: $no-spacing;
    color: $white;
  }

  @media screen and (max-width: $screen_md) {
    span {
      font-size: 16px;
      line-height: 20px;
      font-weight: $light;
    }
  }
}