.card {
  position: relative;
  background: $white;
  border-radius: 4px;
  width: 100%;
  @media (min-width: 768px) {
    width: 45%;
  }
  box-sizing: border-box;
  // max-width: 395px;
  // flex: 33%;
  height: 160px;
  padding: 24px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;

  .logo-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;

    img.first-img {
      display: unset;
    }

    img.second-img {
      display: none;
    }
  }

  .info {
    opacity: 0;
    width: 200px;
    font-family: $rajdhani;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: $no-spacing;
    color: $white;
    text-align: left;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #fff;
    background: #262fc5 0% 0% no-repeat padding-box;

    .info {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    .logo-wrapper {
      transform: translateX(-30%);
      transition: all 0.3s ease-in-out;

      img.first-img {
        display: none;
      }

      img.second-img {
        display: unset;
      }
    }
  }

  @media screen and (max-width: $screen_sm) {
    width: 98%;
    max-width: 450px;

    .info {
      font-size: 16px;
      line-height: 20px;
      width: 54%;
      margin-bottom: 0;
    }

    &:hover {
      .logo-wrapper {
        transform: translateX(-26%);
      }
    }
  }
}
