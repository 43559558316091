* {
  box-sizing: border-box;
}

body {
  color: --white;
  font-family: --roboto;
  font-weight: 400;
  position: relative;
  display: block;
  background-color: #121125;
}

html{
  scroll-behavior: smooth;
}

a {
  text-decoration: none;

  &:hover { text-decoration: none; }
}

.animeShow{
  opacity: 0;
  position: relative;
  transform: translate3d(0, 10px, 0);
  animation: show .6s ease-in forwards;
}

@keyframes show {
  to{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}