@use '../variables';

.smart-form {
  position: relative;
  padding-top: 80px;
  background: url("../../img/bg/Grupo\ 153.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 940px;

  .container-form {
    width: 955px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bg-m {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-220px, 75px);
    max-width: 100%;
  }

  .info {
    position: relative;
    margin-top: 213px;
    max-width: 250px;
    z-index: 2;

    .title-form {
      font-family: $rajdhani;
      font-size: 40px;
      line-height: 51px;
      font-weight: $regular;
      letter-spacing: $no-spacing;
      color: $white;
      margin-bottom: 8px;

      .smart {
        color: $orange;
      }
    }

    .descricao {
      font-family: $rajdhani;
      font-size: 20px;
      line-height: 26px;
      font-weight: $regular;
      text-align: left;
    }
  }

  .form {
    position: relative;
    z-index: 2;
    min-width: 566px;
    max-width: 566px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 80px;

    .container-button-g {
      width: 100%;

      button {
        min-height: 60px;
      }
    }

    label {
      width: 100%;
      font-family: $roboto;
      font-size: 14px;
      line-height: 22px;
      font-weight: $light;
      letter-spacing: $no-spacing;
      color: $blue;
      opacity: 1;
    }

    .double {
      width: 100%;
      justify-content: space-between;
      display: flex;
      gap: 1rem;

      select,
      input {
        width: 100%;

        &::placeholder {
          padding-left: 1rem;
        }
      }
    }

    .one {

      select,
      input {
        width: 100%;

        &::placeholder {
          padding-left: 1rem;
        }
      }

      textarea {
        width: 100%;
        padding: 10px 6px 6px;
        min-height: 104px;

        &::placeholder {
          padding-left: 1rem;
        }
      }
    }

    .text-bigger {
      font-size: 18px;
    }
  }

  textarea,
  input,
  select {
    border-radius: 4px;
    border: none;
    min-height: 40px;
    max-height: 40px;
  }

  input {
    padding-left: 6px;
    padding-right: 6px;
  }

  select {
    padding-left: 4px;
    padding-right: 4px;
  }

  .termos {
    display: flex;
    flex-direction: column;

    label {
      font-family: $rajdhani;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: $no-spacing;
      color: $white;
      

      display: flex;
      align-items: center;
      gap: 8px;

      a {
        color: $blue;
      }

      @media screen and (max-width: 632px) {
        padding: 4px 0;
        text-shadow: 1px 1px 2px black, 0 0 1em $darkBlue, 0 0 0.2em $darkBlue;
      }
    }
  }

  @media screen and (max-width: $screen_md) {
    .container-form {
      max-width: calc(100% - 32px);
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .info {
        margin-top: 0;

        .title-form, .descricao {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 905px) {
  .smart-form {
    flex-direction: column !important;
    align-items: center;

    .form {
      margin-bottom: 80px;
    }
  }

  .info {
    max-width: 566px !important;
    margin-bottom: 1rem;
    margin-top: unset !important;
  }

}

@media only screen and (max-width: 632px) {
  .form {
    min-width: 90% !important;
    max-width: 90% !important;
    justify-content: center;

    .double {
      flex-direction: column;
      // padding-left: 1rem;
      // padding-right: 1rem;
    }
  }
}